import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

// subscriptions list
export const postTickets = createAsyncThunk('tickets/getAllData', async (message) => {
  const response = await axios.post('/tickets', {message})
  return response.data
})

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: {
    permission: {},
    data: [],
    loading: false
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})
export const { setLoading } = ticketsSlice.actions

export default ticketsSlice.reducer
